import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Container } from 'reactstrap';
import Layout from '../Layout/Layout';
import { Col, Row } from 'reactstrap';
import './stats-generator.css';
import { useEffect, useState } from 'react';
import { ApiService } from '../../core/api-service';
import { DatePicker, Panel, ButtonGroup, Button, Stack } from 'rsuite';
import { useHistory, useLocation } from 'react-router';
import { getMonthsNo, QuickActions } from './quick-actions';
import {
    addDays,
    getDaysInMonth,
    getStartOfDay,
    isoFormat,
    isOverlap,
    parseDateString,
    subtractDays,
    subtractMonths,
    today,
} from '../run-results/utils/dateUtils';
import { FormDataModel } from './form-data-model';
import { SelectPicker } from 'rsuite';
import BqcSelect from '../ui/bqc-select';

type DropdownOptionType = {
    value: string;
    label: string;
};

type ReportConfirmationResponseType = {
    identifier: string;
    navStatus: number;
}

const TOO_FEW_RECORDS_STATUS_CODE = 100;
const TOO_FEW_RECORDS_MESSAGE = "Your selection comprises less than 30 test results. Please extend the date range or add more lot- / batchnumbers.";

const StatsGenerator = (props: any) => {
    let {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();
    const location = useLocation();

    const [dataModel, setDataModel] = useState<FormDataModel[]>([]);

    const [startDate, setStartDate] = useState<Date>(today());
    const [endDate, setEndDate] = useState<Date>(today());
    const [minDate, setMinDate] = useState<Date>();

    const [products, setProducts] = useState<DropdownOptionType[]>([]);
    const [markers, setMarkers] = useState<DropdownOptionType[]>([]);
    const [lotNumbers, setLotNumbers] = useState<
        string[]
    >([]);
    const [batchIds, setBatchIds] = useState<string[]>([]);

    const [selectedProductNo, setSelectedProductNo] = useState<string>();
    const [selectedMarkerNo, setSelectedMarkerNo] = useState<string>();
    const [selectedLotNo, setSelectedLotNo] = useState<string>();
    const [selectedBatchId, setSelectedBatchId] = useState<string>();

    const [loading, setLoading] = useState<boolean>();
    const [confirmationResponseMessage, setConfirmationResponseMessage] = useState<string | null>(null);

    const [formDataEndpoint, setFormDataEndpoint] = useState<string | null>(null);
    const [titleSuffix, setTitleSuffix] = useState<string>("");
    const [productTypeLabel, setProductTypeLabel] = useState<string>("");

    useEffect(() => {
        if (formDataEndpoint) {
            fetchFormData(formDataEndpoint)
            .then((items) => {
                setDataModel(items);
                setDateTimeFields(items);
                setProductsDataModel(items);
            })
            .catch((err: any) => {
                alert('There was an error fetching your data!');
                console.error(err);
            });
        }
    }, [formDataEndpoint]);

    useEffect(() => {
        configureSectionData();
    }, [])

    useEffect(() => {
        if (confirmationResponseMessage) {
            setConfirmationResponseMessage(null);
        }
    }, [selectedProductNo, selectedMarkerNo, selectedLotNo, selectedBatchId, startDate, endDate]);

    const configureSectionData = () => {
        let target = location.pathname.split("/")[2].split("-")[0];
        if (target === "seraq") {
            setFormDataEndpoint(`protected/runresult/run-results/serology`);
            setTitleSuffix("Serology");
            setProductTypeLabel("SeraQ");
        } else if (target === "viraq") {
            setFormDataEndpoint(`protected/runresult/run-results/virology`);
            setTitleSuffix("NAT");
            setProductTypeLabel("ViraQ");
        }
    }

    const setDateTimeFields = (items: FormDataModel[]) => {
        if (items.length === 0) return;
        const startDates = items
            .map((item) => item.startDate)
            .sort((a, b) => a.valueOf() - b.valueOf());
        setStartDate(startDates[0]);
        setMinDate(startDates[0]);
        if (startDate > endDate) {
            setEndDate(addDays(startDate, 1));
        }
    };

    const setProductsDataModel = (items: FormDataModel[]) => {
        const productsDict: { [key: string]: DropdownOptionType } = {};
        items.forEach((element) => {
            productsDict[element.item_No] = {
                value: element.item_No,
                label: element.product_Name,
            };
        });

        const unique = [];
        for (let key in productsDict) {
            unique.push(productsDict[key]);
        }

        setProducts(unique);
    };

    const setMarkersDataModel = (items: FormDataModel[], productNo: string) => {
        const markersDict: { [key: string]: DropdownOptionType } = {};
        items
            .filter((rr) => rr.item_No === productNo)
            .forEach((element) => {
                markersDict[element.marker_ID] = {
                    value: element.marker_ID,
                    label: element.marker_ID_Name,
                };
            });

        const unique = [];
        for (let key in markersDict) {
            unique.push(markersDict[key]);
        }

        setMarkers(unique);
    };

    const setLotNumbersDataModel = (
        items: FormDataModel[],
        productNo: string | undefined,
        markerID: string | undefined,
        start: Date | null,
        end: Date | null
    ) => {
        if (!start || !end) return;
        const lotNoOptions: string[] = items
            .filter((item) => item.item_No === productNo && item.marker_ID === markerID)
            .filter((item) =>
                isOverlap(
                    start,
                    end,
                    item.lot_no_Start,
                    item.lot_no_Expired
                )
            )
            .map((item) => item.lot_no);
        const unique = Array.from(
            new Set(
                lotNoOptions
                    .map((item) => item)
                    .filter((item) => item !== '')
            )
        );
        setLotNumbers(unique);
    };

    const setBatchIdsDataModel = (
        items: FormDataModel[],
        productNo: string | undefined,
        markerID: string | undefined,
        start: Date | null,
        end: Date | null
    ) => {
        if (!start || !end) return;
        const batchIdOptions: string[] = items
            .filter((item) => item.item_No === productNo && item.marker_ID === markerID)
            .filter((item) =>
                isOverlap(
                    start,
                    end,
                    item.batch_ID_Start_Date,
                    item.batch_ID_exp_date
                )
            )
            .map((item) => item.batch_ID);
        const unique = Array.from(
            new Set(
                batchIdOptions.map((item) => item).filter((item) => item !== '')
            )
        );
        setBatchIds(unique);
    };

    const fetchFormData = async (formDataEndpoint: string): Promise<FormDataModel[]> => {
        setLoading(true);
        const apiService = new ApiService();
        const response = await apiService.get(formDataEndpoint);
        const parsedResponse = response.map((r: FormDataModel) => ({
            ...r,
            startDate: parseDateString(r.startDate),
            lot_no_Start: parseDateString(
                r.lot_no_Start
            ),
            lot_no_Expired: parseDateString(
                r.lot_no_Expired
            ),
            batch_ID_Start_Date: parseDateString(
                r.batch_ID_Start_Date
            ),
            batch_ID_exp_date: parseDateString(
                r.batch_ID_exp_date
            ),
        }));

        setLoading(false);
        return parsedResponse;
    };

    const onProductChange = (value: string) => {
        setSelectedProductNo(value);
        setMarkersDataModel(dataModel, value);

        setSelectedMarkerNo('');
        setSelectedLotNo('');
        setSelectedBatchId('');

        if (!value) {
            const jdate: Date = minDate || today();
            setStartDate(jdate);
            setEndDate(today());
        }
    };

    const onMarkerNoChange = (value: string) => {
        setSelectedMarkerNo(value);
        setSelectedLotNo('');
        setSelectedBatchId('');
        setLotNumbersDataModel(dataModel, selectedProductNo, value, startDate, endDate);
        setBatchIdsDataModel(dataModel, selectedProductNo, value, startDate, endDate);
    };

    const onLotNoChange = (value: string) => {
        setSelectedLotNo(value);
    };

    const onBatchIdChange = (value: string) => {
        setSelectedBatchId(value);
    };

    const history = useHistory();

    const onSubmit = async (formData: any) => {
        const apiService = new ApiService();
        try {
            let reportConfirmationResponse = await apiService.post(`protected/runresult/run-results/report`, {
                ...formData,
                startDate: isoFormat(startDate),
                endDate: isoFormat(endDate),
                queryType: props.queryType
            });
            let reportConfirmationData: ReportConfirmationResponseType = reportConfirmationResponse.data;
            if (reportConfirmationData.navStatus === TOO_FEW_RECORDS_STATUS_CODE) {
                setConfirmationResponseMessage(TOO_FEW_RECORDS_MESSAGE);
            } else {
                history.push('/report-confirm');
            }
        } catch (error) {
            alert('Failed to send report request, please try again later.');
        }
    };

    // const handleQuickOptionsCriteria = (criteria: QuickActions) => {
    //     //clearFilters();

    //     if (criteria.month) {
    //         const now = today();
    //         const startDay = new Date(now.getFullYear(), criteria.month, 1);
    //         setStartDate(startDay);
    //         const endDay = new Date(
    //             now.getFullYear(),
    //             criteria.month,
    //             getDaysInMonth(startDay)
    //         );
    //         setEndDate(endDay);
    //     } else if (criteria.year) {
    //         setStartDate(new Date(criteria.year, 0, 1));
    //         setEndDate(new Date(criteria.year, 11, 31));
    //     } else if (criteria.period) {
    //         setStartDate(subtractMonths(endDate, getMonthsNo(criteria.period)));
    //     }
    // };

    return (
        <Layout contentFluid={true}>
            <Container>
                <h4 className="h4 fw-normal form-title">
                    Analysis &amp; Statistics - {titleSuffix}
                </h4>
                <Panel bordered className="white">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="form-row">
                            <Col>
                                <label htmlFor="selectProduct">
                                    {productTypeLabel} Product: {selectedProductNo}
                                </label>
                                <Controller
                                    control={control}
                                    name="productKey"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <SelectPicker
                                            data={products.map(
                                                (item: DropdownOptionType) => ({
                                                    label: item.label,
                                                    value: item.value,
                                                })
                                            )}
                                            className="fullWidth"
                                            value={selectedProductNo}
                                            onChange={(value, event) => {
                                                onChange(value);
                                                onProductChange(value);
                                            }}
                                            placeholder="Select a product"
                                            size="lg"
                                        />
                                    )}
                                />
                                <span className="form-error">
                                    {errors.productKey &&
                                        'Product key is required'}
                                </span>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row className="form-row">
                            <Col>
                                <label htmlFor="startDate">Start date:</label>
                                <DatePicker
                                    format="dd-MM-yyyy"
                                    size="lg"
                                    className="newLotNoExpiryDate-input custom-date-picker"
                                    id="startDate"
                                    cleanable={false}
                                    value={startDate}
                                    onChange={(value) => {
                                        setStartDate(value || today());
                                        setLotNumbersDataModel(
                                            dataModel,
                                            selectedProductNo,
                                            selectedMarkerNo,
                                            value,
                                            endDate
                                        );
                                        setBatchIdsDataModel(
                                            dataModel,
                                            selectedProductNo,
                                            selectedMarkerNo,
                                            value,
                                            endDate
                                        );
                                    }}
                                    disabledDate={(date: Date | undefined) => {
                                        if (!minDate || !date) return false;
                                        return date < minDate;
                                    }}
                                    disabled={!selectedProductNo}
                                />
                                <span className="form-error">
                                    {errors.startDate &&
                                        'Start date is required'}
                                </span>
                            </Col>
                            <Col>
                                <label htmlFor="endDate">End date:</label>
                                <DatePicker
                                    format="dd-MM-yyyy"
                                    size="lg"
                                    className="newLotNoExpiryDate-input custom-date-picker"
                                    id="endDate"
                                    cleanable={false}
                                    value={endDate}
                                    onChange={(value) => {
                                        setEndDate(value || today());
                                        setLotNumbersDataModel(
                                            dataModel,
                                            selectedProductNo,
                                            selectedMarkerNo,
                                            startDate,
                                            value
                                        );
                                        setBatchIdsDataModel(
                                            dataModel,
                                            selectedProductNo,
                                            selectedMarkerNo,
                                            startDate,
                                            value
                                        );
                                    }}
                                    disabledDate={(date: Date | undefined) => {
                                        if (!startDate || !date) return false;
                                        return (
                                            getStartOfDay(date) <
                                            getStartOfDay(startDate)
                                        );
                                    }}
                                    disabled={!selectedProductNo}
                                />
                                <span className="form-error">
                                    {errors.endDate && 'End date is required'}
                                </span>
                            </Col>
                        </Row>
                        {/* <Row className="form-row">
                            <Col>
                                <QuickActionsComponent onCriteriaFilled={(criteria: QuickActions) => handleQuickOptionsCriteria(criteria)} />                              
                            </Col>
                        </Row> */}
                        <Row className="form-row">
                            <Col>
                                <label htmlFor="markerNo">Marker:</label>
                                <Controller
                                    control={control}
                                    name="markerNo"
                                    rules={{ required: true }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <SelectPicker
                                            data={markers.map(
                                                (item: DropdownOptionType) => ({
                                                    label: item.label,
                                                    value: item.value,
                                                })
                                            )}
                                            className="fullWidth"
                                            value={selectedMarkerNo}
                                            onChange={(value, event) => {
                                                onChange(value);
                                                onMarkerNoChange(value);
                                            }}
                                            disabled={!selectedProductNo}
                                            placeholder="Select a marker"
                                            size="lg"
                                        />
                                    )}
                                />
                                <span className="form-error">
                                    {errors.markerNo && 'Marker is required'}
                                </span>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row className="form-row">
                            <Col>
                                <label htmlFor="selectLotNo">
                                    Lot No: (Optional)
                                </label>
                                <Controller
                                    control={control}
                                    name="lotNo"
                                    rules={{ required: false }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <SelectPicker
                                            data={lotNumbers.map(
                                                (item: string) => ({
                                                    label: item,
                                                    value: item,
                                                })
                                            )}
                                            className="fullWidth"
                                            value={selectedLotNo}
                                            onChange={(value, event) => {
                                                onChange(value);
                                                onLotNoChange(value);
                                            }}
                                            disabled={!selectedMarkerNo}
                                            placeholder="All"
                                            size="lg"
                                        />
                                    )}
                                />
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row className="form-row">
                            <Col>
                                <label htmlFor="selectBatchId">
                                    {productTypeLabel} Batch-ID: (Optional)
                                </label>
                                <Controller
                                    control={control}
                                    name="batchId"
                                    rules={{ required: false }}
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <SelectPicker
                                            data={batchIds.map(
                                                (item: string) => ({
                                                    label: item,
                                                    value: item,
                                                })
                                            )}
                                            className="fullWidth"
                                            value={selectedBatchId}
                                            onChange={(value, event) => {
                                                onChange(value);
                                                onBatchIdChange(value);
                                            }}
                                            disabled={!selectedMarkerNo}
                                            placeholder="All"
                                            size="lg"
                                        />
                                    )}
                                />
                            </Col>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Col>
                                <input
                                    className="btn btn-primary btn-lg fullWidth"
                                    type="submit"
                                    disabled={props.posting}
                                    value="Generate Statistical Report"
                                />
                            </Col>
                        </Row>

                        {confirmationResponseMessage &&
                            <>
                                <Row>
                                    <Col className='confirmation-response-message'>
                                        {confirmationResponseMessage}
                                    </Col>
                                </Row>
                            </>}
                    </form>
                </Panel>
            </Container>
        </Layout>
    );
};

export default StatsGenerator;
