import React from 'react';
import { useLocation } from 'react-router';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import "./Breadcrumbs.css";

const breadcrumbs_list = [
    { path: "", breadcrumb: "Lab Profile", href: "/" },
    { path: "privacy-policy", breadcrumb: "Privacy Policy", href: "/privacy-policy" },
    { path: "serology-lab-equipment", breadcrumb: "Serology Lab Equipment", href: "data-entry/serology-lab-equipment"},
    { path: "nat-lab-equipment", breadcrumb: "NAT Lab Equipment", href: "data-entry/nat-lab-equipment"},
    { path: "seraq", breadcrumb: "SeraQ Run Controls", href: "data-entry/seraq" },
    { path: "viraq", breadcrumb: "ViraQ Run Controls", href: "data-entry/viraq" },
    { path: "seraq-performance-report", breadcrumb: "SeraQ Run Control Reproducibility Reports", href: "generate-statistics/seraq-performance-report" },
    { path: "seraq-specificity-report", breadcrumb: "Serologic Assay Specificity & Robustness Report", href: "generate-statistics/seraq-specificity-report" },
    { path: "viraq-performance-report", breadcrumb: "ViraQ Run Control Reproducibility Reports", href: "generate-statistics/viraq-performance-report" },
    { path: "viraq-specificity-report", breadcrumb: "NAT Assay Specificity & Robustness Report", href: "generate-statistics/viraq-specificity-report" },
    { path: "run-results-seraq", breadcrumb: "SeraQ Results", href: "data-entry/seraq/run-results-seraq" },
    { path: "run-results-roche", breadcrumb: "Roche Results", href: "data-entry/viraq/roche/run-results-roche" },
    { path: "run-results-grifols", breadcrumb: "Grifols Results", href: "data-entry/viraq/roche/run-results-grifols" }
];

const Breadcrumbs = () => {
    const location = useLocation();

    const getBreadcrumbsList = () => {
        const fullPath: string = location.pathname;
        let paths: string[] = [];

        if (fullPath === "/") {
            paths.push("");
        } else {
            paths = fullPath.split("/");
            
            let runResultsIndex = paths.findIndex(path => path.indexOf("run-results-") !== -1);
            if (runResultsIndex !== -1) {
                paths = paths.splice(0, runResultsIndex + 1);
            }
        }
      
        return paths.map((path: string, index: number) => {
            const current_breadcrumb = breadcrumbs_list.find((crumb) => {
                return crumb.path === path;
            });

            if (current_breadcrumb) {
                return index === paths.length - 1 ? (
                    <BreadcrumbItem key={current_breadcrumb.breadcrumb}>
                        {current_breadcrumb.breadcrumb}
                    </BreadcrumbItem>
                ) : (
                    <BreadcrumbItem key={current_breadcrumb.breadcrumb}>
                        <a href={current_breadcrumb.href}>{current_breadcrumb.breadcrumb}</a>
                    </BreadcrumbItem>
                );
            }
        });
    }

    return (
            <Breadcrumb className="breadcrumbs-container">
                {getBreadcrumbsList()}
            </Breadcrumb>
    )
}

export default Breadcrumbs;